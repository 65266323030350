<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="editFieldValidation">
            <b-form @submit.prevent>
                <b-card>
                    <template v-if='field'>
                        <b-row>
                            <b-col><h3>{{ $t("general.edit_field") }}</h3></b-col>
                            <b-col class="text-right">
                                <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols='12' sm='12'>
                                <validation-provider
                                    :name="$t('general.field_name')"
                                    #default="{ errors }"
                                    rules="required"
                                >
                                    <label for='field_name'>{{ $t('general.field_name') }}</label>
                                    <b-form-input id='field_name' v-model="field.Name"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1">
                            <b-col class="text-right">
                                <b-button @click="validationForm" variant="primary">{{ $t('general.save') }}</b-button>
                            </b-col>
                        </b-row>
                    </template>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BFormInput, BForm, BButton} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required } from '@validations'
    export default {
        components: {BCol, BRow, BOverlay, BCard, BFormInput, BForm, BButton, ValidationProvider, ValidationObserver},
        data() {
            return {
                id: 0,
                field_name: '',

                showLoader: false,
                field: null,
                required
            }
        },
        methods: {
            validationForm() {
                this.$refs.editFieldValidation.validate().then((response) => {
                    if (response) {
                        this.onEditField()
                    }
                })
            },
            onEditField() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Name: thisIns.field.Name
                }

                thisIns.$http.patch(`/v1/admin/podrocja/${this.id}`, payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.field_successfully_edited')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.go(-1)
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.edit_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            loadData() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get(`/v1/admin/podrocja/${thisIns.id}`)
                    .then(res => {
                        thisIns.field = res.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },

        created()  {
            this.id = this.$route.params.field_id
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>